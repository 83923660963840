import smoothscroll from 'smoothscroll-polyfill';

export default function () {
  smoothscroll.polyfill();
  const anchors = document.getElementsByTagName('a');
  for (let i = 0; i < anchors.length; i++) {
    let anchor = anchors[i];
    anchor.addEventListener('click', function(event) {
      const link = this.getAttribute('href');
      if (link[0] == '#') {
        event.preventDefault();
        document.querySelector(link).scrollIntoView({ behavior: 'smooth' });
      }
    });
  }
}