export default function () {
  const client = ShopifyBuy.buildClient({
    domain: 'whale-home-books.myshopify.com',
    storefrontAccessToken: '730c46942169f8ec2de8339591d07b96',
  })

  const ui = ShopifyBuy.UI.init(client)

  return ui.createComponent('collection', {
    id: '221487235225',
    node: document.querySelector('.shopify-block'),
    options: {
      productSet: {
        events: {
          afterRender(c) {
            c.node.dispatchEvent(new CustomEvent('store_rendered', { bubbles: true, detail: c.model.products.length}));
          }
        },
        iframe: false,
        contents: {
          pagination: false
        },
      },
      product: {
        events: {
          afterInit(c) {
            c.node.dispatchEvent(new Event('product_init', { bubbles: true }));
          }
        },
        order: [
          'img',
          'imgWithCarousel',
          'title',
          'variantTitle',
          'options',
          'quantity',
          'price',
          'button',
          'buttonWithQuantity',
          'description',
        ],
        contents: {
          quantity: true,
          description: true
        },
        templates: {
          img: '<div class="shopify-buy__container">{{#data.currentImage.srcLarge}}<div class="{{data.classes.product.imgWrapper}}" data-element="product.imgWrapper"><img alt="{{data.currentImage.altText}}" data-element="product.img" class="{{data.classes.product.img}}" src="{{data.currentImage.srcLarge}}" /></div>{{/data.currentImage.srcLarge}}',
          title: '<div class="shopify-buy__content-wrapper"><h1 class="{{data.classes.product.title}}" data-element="product.title">{{data.title}}</h1>',
          quantity: `<div class="quantity-price-wrapper"><div class="{{data.classes.product.quantity}} {{data.quantityClass}}" data-element="product.quantity">
          {{#data.contents.quantityInput}}
          <label for="quantity">Quantity</label>
          <input class="{{data.classes.product.quantityInput}}" name="quantity" type="number" min="0" aria-label="Quantity" value="{{data.selectedQuantity}}" data-element="product.quantityInput">
          {{/data.contents.quantityInput}}
          </div>`,
          button: '<div class="{{data.classes.product.buttonWrapper}}" data-element="product.buttonWrapper"><button {{#data.buttonDisabled}}disabled{{/data.buttonDisabled}} class="{{data.classes.product.button}} {{data.buttonClass}}" data-element="product.button" data-category="AddToCart">{{data.buttonText}}</button></div></div>',
          description: '<div class="{{data.classes.product.description}}" data-element="product.description">{{{data.descriptionHtml}}}</div></div></div>',
        },
      },
      cart: {
        popup: false
      }
    },
  })
}