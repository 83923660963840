const processForm = (form) => {
  const data = new FormData(form);
  data.append('form-name', 'mailing-list');
  fetch('/', {
    method: 'POST',
    body: data,
  })
    .then(() => {
      form.innerHTML = `<div class="form-message">Thanks for subscribing! ❤️</div>`;
    })
    .catch((error) => {
      form.innerHTML = `<div class="form-message">Oops! ${error}</div>`;
    });
};

export default function () {
  const subscribeForm = document.querySelector('.subscribe-form');
  if (subscribeForm) {
    subscribeForm.addEventListener('submit', (e) => {
      e.preventDefault();
      processForm(subscribeForm);
    });
  }
}
