export default function () {
  const unicorn = document.querySelector('.unicorn');

  window.addEventListener('scroll', function (e) {
    let pos = window.pageYOffset;

    if (pos >= 0 && pos < 240) {
      pos = 64;
    } else if (pos >= 240 && pos < 1240) {
      pos = 1022;
    } else if (pos >= 1240 && pos < 2240) {
      pos = 1972;
    } else if (pos >= 2240 && pos < 3240) {
      pos = 3032;
    } else if (pos >= 3240 && pos < 4240) {
      pos = 3982;
    } else if (pos >= 4240 && pos < 5040) {
      pos = 4932;
    } else if (pos >= 5040) {
      pos = 5562;
    }

    unicorn.style.top = `${pos}px`;
  });
}
