function trackEvent(action, category, label) {
  gtag('event', action, {
    event_category: category,
    event_label: label || window.location.pathname,
  });
}

export default function () {
  document.body.addEventListener('click', function (e) {
    let category;
    if (e.target.nodeName === 'A') {
      category = e.target.dataset.category ? e.target.dataset.category : 'link';
    } else if (e.target.parentNode.nodeName === 'A') {
      category = e.target.parentNode.dataset.category
        ? e.target.parentNode.dataset.category
        : 'link';
    }
    // svg links
    else if (e.target.nodeName === 'path') {
      category = e.target.parentNode.parentNode.dataset.category
        ? e.target.parentNode.parentNode.dataset.category
        : 'link';
    }
    trackEvent('click', category);
  });
}
