import { initSlider } from './slider';
import initShopify from './shopify';
import initUnicorn from './unicorn';
import initScrollTo from './scroll_to';
import initForm from './form';
import initTracking from './tracking';

let totalProducts = 0;
let renderedCount = 0;

window.onload = () => {
  initShopify();
  initUnicorn();
  initScrollTo();
  initForm();
  initTracking();

  const introSliderEl = document.querySelector('.intro-slider');
  const introSlider = initSlider(introSliderEl, 1);
  setInterval(() => {
    introSlider.next();
  }, 4000);

  const reviewsSliderEl = document.querySelector('.reviews-slider');
  initSlider(reviewsSliderEl, 1);

  document.addEventListener('store_rendered', function(e) {
    totalProducts = e.detail;
  })

  document.addEventListener('product_init', function(e) {
    renderedCount++;

    if (renderedCount >= totalProducts) {
      const shopifySliderEl = document.querySelector('.shopify-block');
      initSlider(shopifySliderEl, 1, 'shopify-buy__collection', 'shopify-buy__collection-products', 'shopify-buy__product');
    }
  })
};
