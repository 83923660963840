import { lory } from 'lory.js';

function initSlider(slider, slidesToScroll, frameName = 'lory-frame', slideContainerName = 'lory-slides', slideName = 'lory-slide') {
  const dots = slider.querySelector('.lory-dots');
  const count = Math.ceil(slider.querySelectorAll(`.${slideName}`).length / slidesToScroll);
  const dot = document.createElement('li');

  function dotHandler(e) {
    if (e.type === 'before.lory.init') {
      for (let i = 0; i < count; i++) {
        dots.appendChild(dot.cloneNode());
      }
      dots.children[0].classList.add('active');
    }
    if (e.type === 'after.lory.init') {
      for (let i = 0; i < count; i++) {
        dots.children[i].addEventListener('click', function (e) {
          lorySlider.slideTo(Array.prototype.indexOf.call(dots.children, e.target));
        });
      }
    }
    if (e.type === 'after.lory.slide') {
      for (let i = 0; i < dots.children.length; i++) {
        dots.children[i].classList.remove('active');
      }
      let index = e.detail.currentSlide - slidesToScroll;
      if (slidesToScroll > 1) {
        index = index >= slidesToScroll ? count - 1 : index;
      }
      dots.children[index].classList.add('active');
    }
    if (e.type === 'on.lory.resize') {
      for (let i = 0; i < dots.children.length; i++) {
        dots.children[i].classList.remove('active');
      }
      dots.children[0].classList.add('active');
    }
  }

  slider.addEventListener('before.lory.init', dotHandler);
  slider.addEventListener('after.lory.init', dotHandler);
  slider.addEventListener('after.lory.slide', dotHandler);
  slider.addEventListener('on.lory.resize', dotHandler);

  const lorySlider = lory(slider, {
    infinite: slidesToScroll,
    slidesToScroll,
    enableMouseEvents: true,
    classNameFrame: frameName,
    classNameSlideContainer: slideContainerName,
  });

  return lorySlider;
}

function destroySlider(oldSlider) {
  const newSlider = oldSlider.cloneNode(true);
  oldSlider.parentNode.replaceChild(newSlider, oldSlider);
}

export {
  initSlider,
  destroySlider
}
